import React from 'react';
import PropTypes from 'prop-types';
import FormModal from "../../../components/common/modals/FormModal";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {allEthnicitiesDropdown, allGendersDropdown, allProfessionalDesignationDropdown} from "../../../relay/Fragments";
import {sortByDisplayOrder} from "../../../helpers/utils";
import dayjs from "dayjs";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
import HorizontalFormCheck from "../../../components/common/horizontal-form-inputs/HorizontalFormCheck";
import HorizontalFormSelect from "../../../components/common/horizontal-form-inputs/HorizontalFormSelect";
const EditPractitionerVersionModal = ({showModal, onClose, selectedVersion, genders, ethnicities, professionalDesignations}) => {
    const mutation = graphql`
    mutation EditPractitionerVersionModalMutation($id: ID!, $firstName: String!, $lastName: String!, $middleInitial: String!, 
    $nickName: String!, $nameSuffix: String!, $dateOfBirth: DateInput, $allowedToBePcp: Boolean, $qualification: String!,
        $genderId: StringInput, $ethnicityId: StringInput, $professionalDesignationId: StringInput, $effectiveFrom: DateOnly!, $effectiveTo: DateOnly!) {
        practitioner {
            modifyVersion(input: {practitionerVersionId: $id, firstName: $firstName, lastName: $lastName, middleInitial: $middleInitial, 
            nameSuffix: $nameSuffix, nickname: $nickName, qualification: $qualification, dateOfBirth: $dateOfBirth, allowedToBePcp: $allowedToBePcp,
            genderId: $genderId, ethnicityId: $ethnicityId, professionalDesignationId: $professionalDesignationId, effectiveFrom: $effectiveFrom,
            effectiveTo: $effectiveTo}) {
                practitionerVersion {
                    id
                    practitioner {
                      versions {
                          ...PractitionerVersions_Versions
                      }
                        currentVersion {
                            id
                        }}}}}}`
    const allGenders = useFragment(allGendersDropdown, genders).sort(sortByDisplayOrder).map((l) => {return {label: l.name, value: l.id}});
    const allEthnicities = useFragment(allEthnicitiesDropdown, ethnicities).sort(sortByDisplayOrder).map((l) => {return {label: l.name, value: l.id}});
    const allProfessionalDesignations = useFragment(allProfessionalDesignationDropdown, professionalDesignations).sort(sortByDisplayOrder).map((l) => {return {label: l.name, value: l.id}});
    const [commitMutation, isInFlight] = useMutation(mutation);
    const effectiveFrom = selectedVersion?.effectiveFrom ? dayjs(selectedVersion?.effectiveFrom).toDate() : '';
    const effectiveTo = selectedVersion?.effectiveTo ? dayjs(selectedVersion?.effectiveTo).toDate() : '';
    const dateOfBirth = selectedVersion?.dateOfBirth ? dayjs(selectedVersion?.dateOfBirth).toDate() : '';
    const defaultValues = {
        id: selectedVersion?.id,
        firstName: selectedVersion?.firstName,
        lastName: selectedVersion?.lastName,
        middleInitial: selectedVersion?.middleInitial,
        nickName: selectedVersion?.nickName,
        nameSuffix: selectedVersion?.nameSuffix,
        allowedToBePcp: selectedVersion?.allowedToBePcp,
        qualification: selectedVersion?.qualification,
        genderId: selectedVersion?.gender?.id,
        ethnicityId: selectedVersion?.ethnicity?.id,
        professionalDesignationId: selectedVersion?.professionalDesignation?.id,
        dateOfBirth: dateOfBirth,
        effectiveFrom: effectiveFrom,
        effectiveTo: effectiveTo
    }
    const schema = yup.object({
        firstName: yup.string().required("Language is required"),
        lastName: yup.string().required("Language is required"),
        middleInitial: yup.string().max(1, "Middle Initial can only be 1 letter"),
        dateOfBirth: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
        effectiveFrom: yup.date().required("Effective From is required").isBefore("effectiveTo", "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From")
    });
    const methods = useForm({defaultValues: defaultValues, resolver: yupResolver(schema)});
    const handleSubmit = (data) => {
        commitMutation({variables: {...data}, onCompleted: (data) => {
                if(data.practitioner) {
                    toast.success("Version for Provider updated")
                    methods.reset();
                    onClose();
                }
            }})
    }
    return (
        <FormModal showModal={showModal}
                   onClose={onClose}
                   title={"Update Version"}
                   methods={methods}
                   isInFlight={isInFlight}
                   onSubmit={handleSubmit}
        >
            <HorizontalFormInput controlId={"firstName"} label={"First Name"} />
            <HorizontalFormInput controlId={"middleInitial"} label={"Middle Initial"} />
            <HorizontalFormInput controlId={"lastName"} label={"Last Name"} />
            <HorizontalFormInput controlId={"nameSuffix"} label={"Name Suffix"} />
            <HorizontalFormInput controlId={"nickName"} label={"Nick Name"} />
            <HorizontalFormInput controlId={"qualification"} label={"Qualification"} />
            <HorizontalFormSelect options={allProfessionalDesignations} controlId={"professionalDesignation"} label={"Professional Designation"} />
            <HorizontalFormSelect options={allGenders} controlId={"genderId"} label={"Gender"} />
            <HorizontalFormSelect options={allEthnicities} controlId={"ethnicityId"} label={"Ethnicity"} />
            <HorizontalFormDate controlId={"dateOfBirth"} label={"Date of Birth"} />
            <HorizontalFormCheck controlId={"allowedToBePcp"} label={"Allowed to Be Pcp"} />
            <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
            <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
        </FormModal>
    )
}
EditPractitionerVersionModal.defaultProps = {}
EditPractitionerVersionModal.propTypes = {}
export default EditPractitionerVersionModal