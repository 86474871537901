import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from "react-select/creatable";
import classNames from "classnames";
import DropdownIndicator from "../../../components/common/horizontal-form-inputs/DropdownIndicator";
import ClearIndicator from "../../../components/common/horizontal-form-inputs/ClearIndicator";
import {usePropertyField} from "./usePropertyField";
import {Form} from "react-bootstrap";
import {conformValue} from "../helpers";


const ChangeField = ({property, controlId, status}) => {
    const {field, invalid, error, options, handleOnChange, handleOnCreate, isInFlight, mask} = 
        usePropertyField(controlId, property)
    return (
        <tr className={"align-middle white-space-nowrap"}>
            <th scope={"row"}>{property.propertyName}</th>
            <td>{property.originalValue}</td>
            <td>
                {status === "Undecided" ?
                    <>
                <CreatableSelect
                    placeholder={"Select..."}
                    options={options}
                    isSearchable
                    isClearable
                    components={{DropdownIndicator, ClearIndicator}}
                    className={classNames({'is-invalid': invalid})}
                    classNamePrefix={"react-select"}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: invalid ? "#e63757!important" : 'var(--falcon-input-border-color)'
                        })
                    }}
                    onChange={handleOnChange}
                    onCreateOption={handleOnCreate}
                    isLoading={isInFlight}
                    value={{
                        value: field.value,
                        label:options.find((o) => {return o.value === field.value})?.label
                    }}
                    />
                        {invalid && <Form.Control.Feedback type={"invalid"}>{error.message}</Form.Control.Feedback>}
                </>
                : <>{conformValue(field.value, mask)}</>}
                
            </td>
        </tr>
    )
}
ChangeField.propTypes = {
    property: PropTypes.object.isRequired,
    controlId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
}
export default ChangeField