/**
 * @generated SignedSource<<7655588574864d8e0238002ae23e98ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allowedToBePcp"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateOfBirth"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ethnicityId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "genderId"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "middleInitial"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameSuffix"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nickName"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "professionalDesignationId"
},
v13 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "qualification"
},
v14 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "allowedToBePcp",
        "variableName": "allowedToBePcp"
      },
      {
        "kind": "Variable",
        "name": "dateOfBirth",
        "variableName": "dateOfBirth"
      },
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "ethnicityId",
        "variableName": "ethnicityId"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "genderId",
        "variableName": "genderId"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "middleInitial",
        "variableName": "middleInitial"
      },
      {
        "kind": "Variable",
        "name": "nameSuffix",
        "variableName": "nameSuffix"
      },
      {
        "kind": "Variable",
        "name": "nickname",
        "variableName": "nickName"
      },
      {
        "kind": "Variable",
        "name": "practitionerVersionId",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "professionalDesignationId",
        "variableName": "professionalDesignationId"
      },
      {
        "kind": "Variable",
        "name": "qualification",
        "variableName": "qualification"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PractitionerVersion",
  "kind": "LinkedField",
  "name": "currentVersion",
  "plural": false,
  "selections": [
    (v15/*: any*/)
  ],
  "storageKey": null
},
v17 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditPractitionerVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "PractitionerVersionModifyPayload",
            "kind": "LinkedField",
            "name": "modifyVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerVersion",
                "kind": "LinkedField",
                "name": "practitionerVersion",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerVersions_Versions"
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v5/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v11/*: any*/),
      (v10/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v13/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      (v12/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditPractitionerVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v14/*: any*/),
            "concreteType": "PractitionerVersionModifyPayload",
            "kind": "LinkedField",
            "name": "modifyVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerVersion",
                "kind": "LinkedField",
                "name": "practitionerVersion",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "middleInitial",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nickName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dateOfBirth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameSuffix",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "qualification",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowedToBePcp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gender",
                            "kind": "LinkedField",
                            "name": "gender",
                            "plural": false,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Ethnicity",
                            "kind": "LinkedField",
                            "name": "ethnicity",
                            "plural": false,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfessionalDesignation",
                            "kind": "LinkedField",
                            "name": "professionalDesignation",
                            "plural": false,
                            "selections": (v17/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v16/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "69808e45391878cd949601c5e77c5a9d",
    "id": null,
    "metadata": {},
    "name": "EditPractitionerVersionModalMutation",
    "operationKind": "mutation",
    "text": "mutation EditPractitionerVersionModalMutation(\n  $id: ID!\n  $firstName: String!\n  $lastName: String!\n  $middleInitial: String!\n  $nickName: String!\n  $nameSuffix: String!\n  $dateOfBirth: DateInput\n  $allowedToBePcp: Boolean\n  $qualification: String!\n  $genderId: StringInput\n  $ethnicityId: StringInput\n  $professionalDesignationId: StringInput\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitioner {\n    modifyVersion(input: {practitionerVersionId: $id, firstName: $firstName, lastName: $lastName, middleInitial: $middleInitial, nameSuffix: $nameSuffix, nickname: $nickName, qualification: $qualification, dateOfBirth: $dateOfBirth, allowedToBePcp: $allowedToBePcp, genderId: $genderId, ethnicityId: $ethnicityId, professionalDesignationId: $professionalDesignationId, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {\n      practitionerVersion {\n        id\n        practitioner {\n          versions {\n            ...PractitionerVersions_Versions\n            id\n          }\n          currentVersion {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerVersions_Versions on PractitionerVersion {\n  id\n  firstName\n  lastName\n  middleInitial\n  nickName\n  dateOfBirth\n  nameSuffix\n  qualification\n  allowedToBePcp\n  effectiveFrom\n  effectiveTo\n  gender {\n    name\n    id\n  }\n  ethnicity {\n    name\n    id\n  }\n  professionalDesignation {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "b1a5c45d09dc28c312806cfc11642da0";

export default node;
