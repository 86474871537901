/**
 * @generated SignedSource<<0173462983953eee06a46305081c1187>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveFrom"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "effectiveTo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "middleInitial"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nameSuffix"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nickName"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "practitionerId"
},
v8 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "effectiveFrom",
        "variableName": "effectiveFrom"
      },
      {
        "kind": "Variable",
        "name": "effectiveTo",
        "variableName": "effectiveTo"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "middleInitial",
        "variableName": "middleInitial"
      },
      {
        "kind": "Variable",
        "name": "nameSuffix",
        "variableName": "nameSuffix"
      },
      {
        "kind": "Variable",
        "name": "nickName",
        "variableName": "nickName"
      },
      {
        "kind": "Variable",
        "name": "practitionerId",
        "variableName": "practitionerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "PractitionerVersion",
  "kind": "LinkedField",
  "name": "currentVersion",
  "plural": false,
  "selections": [
    (v9/*: any*/)
  ],
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPractitionerVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "PractitionerVersionModifyPayload",
            "kind": "LinkedField",
            "name": "createVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerVersion",
                "kind": "LinkedField",
                "name": "practitionerVersion",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "PractitionerVersions_Versions"
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NewPractitionerVersionModalMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "PractitionerMutationGroup",
        "kind": "LinkedField",
        "name": "practitioner",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "PractitionerVersionModifyPayload",
            "kind": "LinkedField",
            "name": "createVersion",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "PractitionerVersion",
                "kind": "LinkedField",
                "name": "practitionerVersion",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Practitioner",
                    "kind": "LinkedField",
                    "name": "practitioner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PractitionerVersion",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "middleInitial",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nickName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dateOfBirth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "nameSuffix",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "qualification",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowedToBePcp",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveFrom",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "effectiveTo",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Gender",
                            "kind": "LinkedField",
                            "name": "gender",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Ethnicity",
                            "kind": "LinkedField",
                            "name": "ethnicity",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProfessionalDesignation",
                            "kind": "LinkedField",
                            "name": "professionalDesignation",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1981a8a6620927deecdd452b8a68d35",
    "id": null,
    "metadata": {},
    "name": "NewPractitionerVersionModalMutation",
    "operationKind": "mutation",
    "text": "mutation NewPractitionerVersionModalMutation(\n  $practitionerId: ID!\n  $firstName: String!\n  $lastName: String!\n  $middleInitial: String!\n  $nameSuffix: String!\n  $nickName: String!\n  $effectiveFrom: DateOnly!\n  $effectiveTo: DateOnly!\n) {\n  practitioner {\n    createVersion(input: {firstName: $firstName, lastName: $lastName, middleInitial: $middleInitial, nameSuffix: $nameSuffix, nickName: $nickName, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo, practitionerId: $practitionerId}) {\n      practitionerVersion {\n        id\n        practitioner {\n          versions {\n            ...PractitionerVersions_Versions\n            id\n          }\n          currentVersion {\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment PractitionerVersions_Versions on PractitionerVersion {\n  id\n  firstName\n  lastName\n  middleInitial\n  nickName\n  dateOfBirth\n  nameSuffix\n  qualification\n  allowedToBePcp\n  effectiveFrom\n  effectiveTo\n  gender {\n    name\n    id\n  }\n  ethnicity {\n    name\n    id\n  }\n  professionalDesignation {\n    name\n    id\n  }\n}\n"
  }
};
})();

node.hash = "98245e8bfa7296d7cabad3b29c177f44";

export default node;
