import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {addEditAndDeleteCells, DateCell} from "../../../components/common/advance-table/CustomCells";
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {useUser} from "../../../hooks/useUser";
import AdvanceTableWrapper from "../../../components/common/advance-table/AdvanceTableWrapper";
import {Card} from "react-bootstrap";
import CardTableHeader from "../../../components/common/headers/CardTableHeader";
import AdvanceTable from "../../../components/common/advance-table/AdvanceTable";
import AdvanceTablePagination from "../../../components/common/advance-table/AdvanceTablePagination";
import NewLocationVersionModal from "./NewLocationVersionModal";
import EditLocationVersionModal from "./EditLocationVersionModal";
import DeleteModal from "../../../components/common/modals/DeleteModal";

const columns = [
    {accessor: 'name', Header: 'Name'},
    {accessor: 'description', Header: "Description"},
    {accessor: 'effectiveFrom', Header: 'Effective From', Cell: DateCell},
    {accessor: 'effectiveTo', Header: 'Effective To', Cell: DateCell}
]
const LocationVersions = ({versions, locationId}) => {
    const mutation = graphql`
        mutation LocationVersionsDeleteMutation($id: ID!) {
            deleteNode(input: {id: $id}) {
                owner {
                    ... on Location {
                        id
                        versions {
                            id
                        }
                        currentVersion {
                            name
                            id
                        }
                    }
                }
            }
        }`
    const data = useFragment(graphql`
    fragment LocationVersions_Version on LocationVersion @relay(plural:true){
        id
        name
        description
        effectiveFrom
        effectiveTo
    }`, versions);
    const {hasPermission} = useUser();
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedVersion, setSelectedVersion] = useState({});
    
    const handleEditClick = (data) => {
        setSelectedVersion(data);
        setShowEditModal(true);
    }
    const handleDeleteClick = (data) => {
        setSelectedVersion(data);
        setShowDeleteModal(true);
    }
    
    const cols = useMemo(() => {
        const canEdit = hasPermission("Locations.Update");
        const canDelete = hasPermission("Locations.Delete") && data.length > 1;
        return addEditAndDeleteCells(canEdit, canDelete, columns, handleEditClick, handleDeleteClick)
    }, [hasPermission, data])
    return (
        <>
            <AdvanceTableWrapper columns={cols}
                                 data={data}
                                 sortable
                                 pagination
                                 perPage={10}
            >
             <Card className={"mb-3"}>
                 <Card.Header>
                     <CardTableHeader onNewClick={() => {setShowNewModal(true)}}
                                      title={"Versions"}
                                      table
                                      showNew={hasPermission("Locations.Update")} />
                 </Card.Header>
                 <Card.Body className={"p-0"}>
                     <AdvanceTable table
                                   headerClassName={"bg-200 text-900 text-nowrap align-middle"}
                                   rowClassName={"align-middle white-space-nowrap"}
                                   tableProps={{size: "sm", striped: true, className: "fs--1 mb-0 overflow-hidden"}}
                     />
                 </Card.Body>
                 <Card.Footer>
                     <AdvanceTablePagination table />
                 </Card.Footer>
             </Card>
            </AdvanceTableWrapper>
            <NewLocationVersionModal showModal={showNewModal} 
                                     onClose={() => {setShowNewModal(false)}}
                                     locationId={locationId}
            />
            {selectedVersion && <EditLocationVersionModal showModal={showEditModal} 
                                      onClose={() => {setShowEditModal(false)}}
                                      selectedLocationVersion={selectedVersion}
            />}
            {selectedVersion && <DeleteModal mutation={mutation}
                         onClose={() => {setShowDeleteModal(false)}}
                         thingToDelete={"Version"} 
                         showModal={showDeleteModal}
                         id={selectedVersion?.id}
            >
                    <p>Delete this Location Version: {selectedVersion?.name}?</p>
            </DeleteModal>}
        </>
    )
}
LocationVersions.propTypes = {
    versions: PropTypes.array.isRequired,
    locationId: PropTypes.string.isRequired
}
export default LocationVersions