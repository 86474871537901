import React from 'react';
import PropTypes from 'prop-types';
import {Accordion} from 'react-bootstrap'
import StatusBadge from "./StatusBadge";
import AddField from "./AddField";
import AcceptReject from "./AcceptReject";
import {useWatch} from "react-hook-form";

const AddAccordionItem = ({index, controlId, properties, header, effectiveDate, ...props}) => {
    const status = useWatch({name: controlId + ".status"})
    return (
        <Accordion.Item eventKey={index.toString()}>
            <Accordion.Header>
                Add : {header} as of {effectiveDate}
                <StatusBadge status={status} />
            </Accordion.Header>
            <Accordion.Body>
                {props.children}
                {properties.map((p, propertyIndex) => {
                    return <AddField key={propertyIndex} 
                                     property={p} 
                                     status={status}
                                     controlId={`${controlId}.properties[${propertyIndex}]`}
                    />
                }).sort((a, b) => {return a.props.property.displayOrder - b.props.property.displayOrder})}
                <AcceptReject controlId={controlId} />
            </Accordion.Body>
        </Accordion.Item>
    )
}

AddAccordionItem.propTypes = {
    index: PropTypes.number.isRequired,
    controlId: PropTypes.string.isRequired,
    properties: PropTypes.array.isRequired,
    header:PropTypes.string.isRequired
}
export default AddAccordionItem