import React from 'react';
import PropTypes from 'prop-types';
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import dayjs from "dayjs";
import * as yup from 'yup'
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import FormModal from "../../../components/common/modals/FormModal";
import HorizontalFormInput from "../../../components/common/horizontal-form-inputs/HorizontalFormInput";
import HorizontalFormDate from "../../../components/common/horizontal-form-inputs/HorizontalFormDate";
const EditLocationVersionModal = ({showModal, onClose, selectedLocationVersion}) => {
    const mutation = graphql`
    mutation EditLocationVersionModalMutation($id: ID!, $name: String!, $description:String!, $effectiveFrom: DateOnly!, $effectiveTo:DateOnly!) {
        location {
            modifyVersion(input: {locationVersionId: $id, name: $name, description: $description, effectiveFrom: $effectiveFrom, effectiveTo: $effectiveTo}) {
                locationVersion {
                    id
                    location {
                        versions {
                            ...LocationVersions_Version
                        }
                        currentVersion {
                            id
                            name
                        }
                    }
                }
            }
        }
    }`
    const [commitMutation, isInFlight] = useMutation(mutation);
    const defaultValues = {
        id: selectedLocationVersion.id,
        effectiveFrom: dayjs(selectedLocationVersion.effectiveFrom).toDate(),
        effectiveTo: dayjs(selectedLocationVersion.effectiveTo).toDate(),
        name: selectedLocationVersion.name,
        description: selectedLocationVersion.description
    }
    const schema = yup.object({
        effectiveFrom: yup.date().required("Effective From is required").isBefore('effectiveTo', "Effective From must be before Effective To"),
        effectiveTo: yup.date().required("Effective To is required").isAfter("effectiveFrom", "Effective To must be after Effective From"),
        name: yup.string().required("Name is required")
    })
    const methods = useForm({values: defaultValues, resolver: yupResolver(schema)});
    
    const handleSubmit = (data) => {
        commitMutation({variables: {...data},
        onCompleted: (data) => {
            if(data.location) {
                toast.success("Location Version has been updated")
                onClose();
            }
        }})
    }
    return (
    <FormModal showModal={showModal} 
               onClose={onClose}
               methods={methods}
               isInFlight={isInFlight}
               title={"Edit Location Version"}
               onSubmit={handleSubmit}
    >
        <HorizontalFormInput controlId={"name"} label={"Name"} />
        <HorizontalFormInput controlId={"description"} label={"Description"} />
        <HorizontalFormDate controlId={"effectiveFrom"} label={"Effective From"} />
        <HorizontalFormDate controlId={"effectiveTo"} label={"Effective To"} />
    </FormModal>
   )
}
EditLocationVersionModal.defaultProps = {
}
EditLocationVersionModal.propTypes = {
}
export default EditLocationVersionModal