import React from 'react';
import {Col, Form, Row} from 'react-bootstrap'
import CreatableSelect from "react-select/creatable";
import PropTypes from 'prop-types';
import classNames from "classnames";
import DropdownIndicator from "../../../components/common/horizontal-form-inputs/DropdownIndicator";
import ClearIndicator from "../../../components/common/horizontal-form-inputs/ClearIndicator";
import {usePropertyField} from "./usePropertyField";
import {conformValue, translateMask} from "../helpers";

const AddField = ({property, controlId, status}) => {
    const {field, invalid, error, options, isInFlight, handleOnChange, handleOnCreate, mask} = usePropertyField(controlId, property)
    return (
        <Form.Group as={Row} className={"mb-1"} controlId={controlId + "." + property.propertyName}>
            <Form.Label column xs={7} sm={3}>{property.propertyName}</Form.Label>
            <Col>{status !== "Undecided" ?
                <Form.Control
                    value={field.value === null ? "" : conformValue(field.value, mask)}
                    size={"sm"}
                    disabled
                    plaintext
                />
                :
                <CreatableSelect
                    placeholder={"Select..."}
                    options={options}
                    isSearchable
                    isClearable
                    inputId={controlId + "." + property.propertyName}
                    components={{ClearIndicator, DropdownIndicator}}
                    className={classNames({'is-invalid': invalid})}
                    classNamePrefix={"react-select"}
                    styles={{
                        control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: invalid ? "#e63757!important" : 'var(--falcon-input-border-color)'
                        })
                    }}
                    onChange={(e) => {
                        handleOnChange(e)
                    }}
                    isLoading={isInFlight}
                    onCreateOption={handleOnCreate}
                    value={{
                        value:field.value,
                        label:options.find((o) => {return o.value === field.value})?.label
                    }}
                />}
                {invalid && <Form.Control.Feedback type={"invalid"}>{error.message}</Form.Control.Feedback>}
            </Col>
        </Form.Group>
    )
}
AddField.propTypes = {
    property: PropTypes.object.isRequired,
    controlId: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired
}
export default AddField