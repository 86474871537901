import React from 'react';
import {useLoaderData} from 'react-router-dom';
import {Col, Row} from "react-bootstrap";
import PractitionerInfo from "./PractitionerInfo";
import PractitionerCredentialsInfo from "./PractitionerCredentialsInfo";
import DetailsPageHeader from "../../components/common/headers/DetailsPageHeader";
import graphql from "babel-plugin-relay/macro";
import {usePreloadedQuery} from "react-relay";
import PractitionerIdentifiers from "./identifiers/PractitionerIdentifiers";
import {useUser} from "../../hooks/useUser";
import PractitionerLanguages from "./languages/PractitionerLanguages";
import PractitionerVersions from "./versions/PractitionerVersions";
import {createProviderFullName} from "../../helpers/utils";
import DeletePanel from "../../components/common/repeated-page-panels/DeletePanel";

const PractitionerDetails = () => {
    const loader = useLoaderData();
    const data = usePreloadedQuery(graphql`
    query PractitionerDetailsQuery($id: ID!) {
        node(id: $id) {
            id
            ... on Practitioner {
                ...PractitionerInfo_Practitioner
                currentVersion {
                    firstName,
                    lastName,
                    nameSuffix,
                    middleInitial
                    qualification
                    nickName
                }
                legalContract {
                    id
                    name
                }
                versions {
                    ...PractitionerVersions_Versions
                }
                languages {
                    ...PractitionerLanguages_Languages
                }
                identifiers {
                    ...PractitionerIdentifiers_Identifiers
                }
            }
        }
        allIdentifierUseCodes {
            ...Fragments_AllIdentifierUseCodes
        }
        allIdentifierTypes {
            ...Fragments_AllIdentifierTypes
        }
        allGenders {
            ...Fragments_AllGendersDropdown
        }
        allProfessionalDesignations {
            ...Fragments_AllProfessionalDesignationDropdown
        }
        allLanguages {
            ...Fragments_AllLanguagesDropdown
        }
        allEthnicities {
            ...Fragments_AllEthnicitiesDropdown
        }
        allLegalContracts {
            ...ContractFragments_AllContractsDropdown
        }
    }`, loader.practitionerDetailsQuery);
    const deleteMutation = graphql`
    mutation PractitionerDetails_DeleteMutation($id: ID!) {
        deleteNode(input: {id: $id}) {
            id
        }
    }`
    const {hasPermission} = useUser();
    return (
        <>
            <DetailsPageHeader iconText={"Provider"} iconType={"user-md"} title={createProviderFullName(data.node.currentVersion.firstName, data.node.currentVersion.lastName, data.node.currentVersion.middleInitial, data.node.currentVersion.nameSuffix, data.node.currentVersion.nickName, data.node.currentVersion.qualification)} />
            <PractitionerInfo practitioner={data.node} legalContracts={data.allLegalContracts}/>
            <Row>
                <Col>
                    <PractitionerVersions practitionerId={data.node.id} 
                                          versions={data.node.versions} 
                                          allEthnicities={data.allEthnicities} 
                                          allGenders={data.allGenders} 
                                          allProfessionalDesignations={data.allProfessionalDesignations} 
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    {hasPermission("ProviderIdentifiers.Read") &&  <PractitionerIdentifiers practitionerId={data.node.id} 
                                             identifiers={data.node.identifiers} 
                                             allIdentifierTypes={data.allIdentifierTypes} 
                                             allIdentifierUseCodes={data.allIdentifierUseCodes} 
                    />}
                </Col>
                <Col>
                    <PractitionerLanguages practitionerId={data.node.id} 
                                           languages={data.node.languages}
                                           allLanguages={data.allLanguages}
                    />
                </Col>
            </Row>
            {hasPermission("Providers.Delete") && 
                <DeletePanel mutation={deleteMutation} 
                             redirectUrl={"/practitioners"} 
                             thingToDelete={"Provider"} 
                             id={data.node.id}>
                    In order to delete a provider, there must be only 1 version and no other related data.
            </DeletePanel>}
        </>
    );
};

export default PractitionerDetails;
